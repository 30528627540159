<template>
  <div class="card border-0 bg-success text-white">
    <div class="card-body">
      <div class="d-flex flex-wrap align-items-center">
        <i class="fe fe-server font-size-50 mr-3" />
        <div>
          <div class="font-size-21 font-weight-bold">Databases</div>
          <div class="font-size-15">Total: 78,155</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral21',
}
</script>
